import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import AssignmentIcon from '@mui/icons-material/Assignment';
import BusinessIcon from '@mui/icons-material/Business';
import FolderZipIcon from '@mui/icons-material/FolderZip';
import GroupsIcon from '@mui/icons-material/Groups';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import NotificationsIcon from '@mui/icons-material/Notifications';
import SpeedIcon from '@mui/icons-material/Speed';
import StoreIcon from '@mui/icons-material/Store';
import TungstenIcon from '@mui/icons-material/Tungsten';
import { get } from 'lodash-es';
import { build } from 'typage-url';
import UserIcon from '~/components/icons/User';
import UsersIcon from '~/components/icons/Users';
import { Menu } from '~/components/Sidebar';
import { $ } from '~/routes/path';

export const sidebarMenus: Menu[] = [
  {
    id: 'general',
    title: 'General',
    items: [
      {
        id: 'account',
        title: 'Account',
        path: build($.dashboard.account),
        icon: <UserIcon fontSize="small" />
      },
      {
        id: 'task',
        title: 'Task',
        path: build($.dashboard.task),
        icon: <AssignmentIcon fontSize="small" />
      },
      {
        id: 'notification',
        title: 'Notification',
        path: build($.dashboard.notification),
        icon: <NotificationsIcon fontSize="small" />
      }
    ]
  },
  {
    id: 'management',
    title: 'Management',
    items: [
      {
        id: 'company',
        title: 'Company',
        path: build($.dashboard.company),
        icon: <BusinessIcon fontSize="small" />,
        items: [
          {
            id: 'companyList',
            title: 'List',
            permissions: ['company:list'],
            path: build($.dashboard.company)
          },
          {
            id: 'companyCreate',
            title: 'Create',
            permissions: ['company:create'],
            path: build($.dashboard.company.create)
          },
          {
            id: 'companyDetail',
            isHidden: true,
            permissions: ['company:view']
          }
        ]
      },
      {
        id: 'nmi',
        title: 'NMI',
        path: build($.dashboard.nmi),
        icon: <TungstenIcon fontSize="small" />,
        items: [
          {
            id: 'nmiList',
            title: 'List',
            permissions: ['nmi:list'],
            path: build($.dashboard.nmi)
          },
          {
            id: 'nmiCreate',
            title: 'Create',
            permissions: ['nmi:create'],
            path: build($.dashboard.nmi.create)
          },
          {
            id: 'nmiDetail',
            isHidden: true,
            permissions: ['nmi:view']
          }
        ]
      },
      {
        id: 'mirn',
        title: 'MIRN',
        path: build($.dashboard.mirn),
        icon: <SpeedIcon fontSize="small" />,
        items: [
          {
            id: 'mirnList',
            title: 'List',
            permissions: ['mirn:list'],
            path: build($.dashboard.mirn)
          },
          {
            id: 'mirnCreate',
            title: 'Create',
            permissions: ['mirn:create'],
            path: build($.dashboard.mirn.create)
          },
          {
            id: 'mirnDetail',
            isHidden: true,
            permissions: ['mirn:view']
          }
        ]
      },
      {
        id: 'council',
        title: 'Council',
        path: build($.dashboard.council),
        icon: <AccountBalanceIcon fontSize="small" />,
        items: [
          {
            id: 'councilList',
            title: 'List',
            permissions: ['council:list'],
            path: build($.dashboard.council)
          },
          {
            id: 'councilCreate',
            title: 'Create',
            permissions: ['council:create'],
            path: build($.dashboard.council.create)
          }
        ]
      },
      {
        id: 'project',
        title: 'Project',
        path: build($.dashboard.project),
        icon: <FolderZipIcon fontSize="small" />,
        items: [
          {
            id: 'projectList',
            title: 'List',
            permissions: ['project:list'],
            path: build($.dashboard.project)
          },
          {
            id: 'projectCreate',
            title: 'Create',
            permissions: ['project:create'],
            path: build($.dashboard.project.create)
          },
          {
            id: 'projectEdit',
            title: 'Edit',
            permissions: ['project:create'],
            path: build($.dashboard.project.id),
            isHidden: true
          },
          {
            id: 'projectDetail',
            title: 'Detail',
            permissions: ['project:view'],
            path: build($.dashboard.project.detail.id),
            isHidden: true
          },
          {
            id: 'projectEAReportPreview',
            title: 'Preview',
            permissions: ['project:view'],
            path: build($.preview.report.id),
            isHidden: true
          }
        ]
      },
      {
        id: 'association',
        title: 'Association',
        path: build($.dashboard.association),
        icon: <GroupsIcon fontSize="small" />,
        items: [
          {
            id: 'associationList',
            title: 'List',
            permissions: ['association:list'],
            path: build($.dashboard.association)
          },
          {
            id: 'associationCreate',
            title: 'Create',
            permissions: ['association:create'],
            path: build($.dashboard.association.create)
          }
        ]
      },
      {
        id: 'retailer',
        title: 'Retailer',
        path: build($.dashboard.retailer),
        icon: <StoreIcon fontSize="small" />,
        items: [
          {
            id: 'retailerList',
            title: 'List',
            permissions: ['retailer:list'],
            path: build($.dashboard.retailer)
          },
          {
            id: 'retailerCreate',
            title: 'Create',
            permissions: ['retailer:create'],
            path: build($.dashboard.retailer.create)
          },
          {
            id: 'retailerDetail',
            isHidden: true,
            permissions: ['retailer:view']
          }
        ]
      },
      {
        id: 'lookup',
        title: 'Lookup',
        icon: <MenuBookIcon fontSize="small" />,
        path: build($.dashboard.lookup),
        items: [
          {
            id: 'lookupList',
            title: 'List',
            permissions: ['project:list'],
            path: build($.dashboard.lookup)
          },
          {
            id: 'lookupCreate',
            title: 'Create',
            permissions: ['project:create'],
            path: build($.dashboard.lookup.create)
          }
        ]
      },
      {
        id: 'user',
        title: 'User',
        icon: <UsersIcon fontSize="small" />,
        path: build($.dashboard.user),
        items: [
          {
            id: 'userList',
            title: 'List',
            permissions: ['user:list'],
            path: build($.dashboard.user)
          },
          {
            id: 'userCreate',
            title: 'Create',
            permissions: ['user:create'],
            path: build($.dashboard.user.create)
          }
        ]
      }
    ]
  }
];

export const getAllValuesByKey = (key: string, menus: Menu[] = []): string[] => {
  return menus.reduce((values, menu) => {
    if (get(menu, key)) {
      values.push(get(menu, key));
    }
    if (menu.items?.length) {
      return [...getAllValuesByKey(key, menu.items), ...values];
    }
    return values;
  }, [] as string[]);
};

export const getAllPaths = (): string[] => {
  return getAllValuesByKey('path', sidebarMenus);
};

export const getMenuById = (id: string, menus: Menu[] = sidebarMenus): Menu | undefined => {
  for (const menu of menus) {
    let targetMenu = menu.items?.find((child) => child.id === id);
    if (!targetMenu) {
      targetMenu = getMenuById(id, menu.items || []);
    }
    if (targetMenu) {
      return targetMenu;
    }
  }
};

export const getParentMenuById = (id: string, menus: Menu[] = sidebarMenus): Menu | undefined => {
  for (const menu of menus) {
    let targetMenu = menu.items?.find((child) => child.id === id);
    if (targetMenu) {
      return menu;
    }
    targetMenu = getParentMenuById(id, menu.items || []);
    if (targetMenu) {
      return targetMenu;
    }
  }
};
