import { useEffect } from 'react';
import { useNavigate } from 'react-router';
import { build } from 'typage-url';
import LoadingScreen from '~/components/LoadingScreen';
import { $ } from '~/routes/path';
import { useSession } from './hooks';

export function withSession<T>(Component: React.FC<T>): React.FC<T> {
  return (props: T) => {
    const session = useSession();
    const navigate = useNavigate();

    useEffect(() => {
      session.init().catch(() => navigate(build($.login)));
    }, [session]);

    if (session.initializing) {
      return <LoadingScreen />;
    }

    return <Component {...props} />;
  };
}
