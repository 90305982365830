import { ComponentType } from 'react';
import { useLocation } from 'react-router';
import Lazy from '~/components/Lazy';

export const buildPageComponent = <T extends ComponentType<any>>(factory: () => Promise<{ default: T }>): T => {
  const Component = Lazy<any>(factory);

  return ((props: any) => {
    const location = useLocation();

    return <Component key={location.pathname} {...props} />;
  }) as T;
};
