import { Navigate, RouteObject } from 'react-router';
import { build } from 'typage-url';
import { MeterType } from '~/models/meter';
import ProjectEAReportPreview from '~/pages/dashboard/project/EAReport/ProjectEAReportPreview';
import PreviewLayout from '~/pages/layout/main/PreviewLayout';
import { sidebarMenus } from '~/routes/sidebar';
import {
  AccessDenied,
  Account,
  AssociationDetail,
  AssociationForm,
  AssociationList,
  CompanyDetail,
  CompanyForm,
  CompanyList,
  CompleteNewPassword,
  CouncilDetail,
  CouncilForm,
  CouncilList,
  DashboardHome,
  DashboardLayout,
  ForgotPassword,
  Login,
  LookupFormPage,
  LookupListPage,
  MainLayout,
  MeterForm,
  MIRNDetail,
  MIRNList,
  NMIDetail,
  NMIList,
  NotFound,
  Notification,
  ProjectDetail,
  ProjectForm,
  ProjectList,
  RetailerDetail,
  RetailerForm,
  RetailerList,
  Task,
  UserCreate,
  UserList
} from './pages';
import { $ } from './path';

const routes: RouteObject[] = [
  {
    path: build($),
    element: <MainLayout />,
    children: [
      { path: build($), element: <Navigate to={build($.dashboard)} /> },
      { path: build($.login), element: <Login /> },
      { path: build($.completeNewPassword), element: <CompleteNewPassword /> },
      { path: build($.forgotPassword), element: <ForgotPassword /> },
      { path: build($.accessDenied), element: <AccessDenied /> },
      { path: build($.notFound), element: <NotFound /> },
      { path: '*', element: <NotFound /> }
    ]
  },
  {
    path: build($.preview),
    element: <PreviewLayout />,
    children: [{ path: build($.preview.report.id), element: <ProjectEAReportPreview /> }]
  },
  {
    path: build($.dashboard),
    element: <DashboardLayout sidebarMenu={sidebarMenus} />,
    children: [
      { path: build($.dashboard), element: <DashboardHome /> },
      { path: build($.dashboard.account), element: <Account /> },
      { path: build($.dashboard.task), element: <Task /> },
      { path: build($.dashboard.notification), element: <Notification /> },
      { path: build($.dashboard.user), element: <UserList /> },
      { path: build($.dashboard.user.create), element: <UserCreate /> },
      { path: build($.dashboard.nmi), element: <NMIList /> },
      { path: build($.dashboard.nmi.create), element: <MeterForm type={MeterType.NMI} /> },
      { path: build($.dashboard.nmi.id), element: <MeterForm type={MeterType.NMI} editMode /> },
      { path: build($.dashboard.nmi.detail.id), element: <NMIDetail /> },
      { path: build($.dashboard.mirn), element: <MIRNList /> },
      { path: build($.dashboard.mirn.create), element: <MeterForm type={MeterType.MIRN} /> },
      { path: build($.dashboard.mirn.id), element: <MeterForm type={MeterType.MIRN} editMode /> },
      { path: build($.dashboard.mirn.detail.id), element: <MIRNDetail /> },
      { path: build($.dashboard.retailer), element: <RetailerList /> },
      { path: build($.dashboard.retailer.create), element: <RetailerForm /> },
      { path: build($.dashboard.retailer.id), element: <RetailerForm editMode /> },
      { path: build($.dashboard.retailer.detail.id), element: <RetailerDetail /> },
      { path: build($.dashboard.company), element: <CompanyList /> },
      { path: build($.dashboard.company.create), element: <CompanyForm /> },
      { path: build($.dashboard.company.detail.id), element: <CompanyDetail /> },
      { path: build($.dashboard.company.id), element: <CompanyForm editMode /> },
      { path: build($.dashboard.council), element: <CouncilList /> },
      { path: build($.dashboard.council.create), element: <CouncilForm /> },
      { path: build($.dashboard.council.id), element: <CouncilForm editMode /> },
      { path: build($.dashboard.association.id), element: <AssociationForm editMode /> },
      { path: build($.dashboard.council.detail.id), element: <CouncilDetail /> },
      { path: build($.dashboard.council.id), element: <CouncilForm editMode /> },
      { path: build($.dashboard.association), element: <AssociationList /> },
      { path: build($.dashboard.association.create), element: <AssociationForm /> },
      { path: build($.dashboard.association.id), element: <AssociationForm editMode /> },
      { path: build($.dashboard.association.detail.id), element: <AssociationDetail /> },
      { path: build($.dashboard.project), element: <ProjectList /> },
      { path: build($.dashboard.project.create), element: <ProjectForm /> },
      { path: build($.dashboard.project.id), element: <ProjectForm editMode /> },
      { path: build($.dashboard.project.detail.id), element: <ProjectDetail /> },
      { path: build($.dashboard.lookup), element: <LookupListPage /> },
      { path: build($.dashboard.lookup.create), element: <LookupFormPage /> }
    ]
  }
];

export default routes;
