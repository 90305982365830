import { Box, useTheme } from '@mui/material';
import ScaleLoader from 'react-spinners/ScaleLoader';

export interface LoadingScreenProps {
  loaderColor?: string;
  className?: string;
}

const LoadingScreen: React.FC<LoadingScreenProps> = ({ loaderColor, className }) => {
  const theme = useTheme();

  return (
    <Box
      className={className}
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%'
      }}
    >
      <ScaleLoader color={loaderColor ?? theme.palette.primary.main} />
    </Box>
  );
};

export default LoadingScreen;
