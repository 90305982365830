import { ComponentType, lazy, Suspense } from 'react';
import LoadingScreen from './LoadingScreen';

const Lazy = <T extends ComponentType<any>>(factory: () => Promise<{ default: T }>): T => {
  const Component = lazy(factory);
  return ((props: any) => (
    <Suspense fallback={<LoadingScreen />}>
      <Component {...props} />
    </Suspense>
  )) as T;
};

export default Lazy;
