import Lazy from '~/components/Lazy';
import { withSession } from '~/core/session/withSession';
import { buildPageComponent } from './utils';

export const MainLayout = Lazy(() => import('~/pages/layout/main/MainLayout'));

export const Login = Lazy(() => import('~/pages/authentication/Login'));

export const CompleteNewPassword = Lazy(() => import('~/pages/authentication/CompleteNewPassword'));

export const ForgotPassword = Lazy(() => import('~/pages/authentication/ForgotPassword'));

export const NotFound = Lazy(() => import('~/pages/NotFound'));

export const AccessDenied = Lazy(() => import('~/pages/AccessDenied'));

export const DashboardLayout = withSession(
  buildPageComponent(() => import('~/pages/layout/dashboard/DashboardLayout'))
);

export const DashboardHome = buildPageComponent(() => import('~/pages/dashboard/Home'));

export const NMIList = buildPageComponent(() => import('~/pages/dashboard/meter/NMIList'));

export const NMIDetail = buildPageComponent(() => import('~/pages/dashboard/meter/NMIDetail'));

export const MIRNDetail = buildPageComponent(() => import('~/pages/dashboard/meter/MIRNDetail'));

export const MIRNList = buildPageComponent(() => import('~/pages/dashboard/meter/MIRNList'));

export const MeterForm = buildPageComponent(() => import('~/pages/dashboard/meter/MeterForm'));

export const Account = buildPageComponent(() => import('~/pages/dashboard/account/Account'));

export const Task = buildPageComponent(() => import('~/pages/dashboard/task/TaskList'));

export const Notification = buildPageComponent(() => import('~/pages/dashboard/notification/NotificationList'));

export const UserCreate = buildPageComponent(() => import('~/pages/dashboard/users/UserCreate'));

export const UserList = buildPageComponent(() => import('~/pages/dashboard/users/UserList'));

export const RetailerForm = buildPageComponent(() => import('~/pages/dashboard/retailers/RetailerForm'));

export const RetailerList = buildPageComponent(() => import('~/pages/dashboard/retailers/RetailerList'));

export const RetailerDetail = buildPageComponent(() => import('~/pages/dashboard/retailers/RetailerDetail'));

export const CompanyForm = buildPageComponent(() => import('~/pages/dashboard/company/CompanyForm'));

export const CompanyDetail = buildPageComponent(() => import('~/pages/dashboard/company/CompanyDetail'));

export const CompanyList = buildPageComponent(() => import('~/pages/dashboard/company/CompanyList'));

export const CouncilList = buildPageComponent(() => import('~/pages/dashboard/council/CouncilList'));

export const CouncilForm = buildPageComponent(() => import('~/pages/dashboard/council/CouncilForm'));

export const CouncilDetail = buildPageComponent(() => import('~/pages/dashboard/council/CouncilDetail'));

export const AssociationList = buildPageComponent(() => import('~/pages/dashboard/association/AssociationList'));

export const AssociationForm = buildPageComponent(() => import('~/pages/dashboard/association/AssociationForm'));

export const AssociationDetail = buildPageComponent(() => import('~/pages/dashboard/association/AssociationDetail'));

export const ProjectList = buildPageComponent(() => import('~/pages/dashboard/project/ProjectList'));

export const ProjectForm = buildPageComponent(() => import('~/pages/dashboard/project/ProjectForm'));

export const ProjectDetail = buildPageComponent(() => import('~/pages/dashboard/project/ProjectDetail'));

export const LookupListPage = buildPageComponent(() => import('~/pages/dashboard/lookup/LookupListPage'));

export const LookupFormPage = buildPageComponent(() => import('~/pages/dashboard/lookup/LookupFormPage'));

export const ProjectEAReportPreviewPage = buildPageComponent(
  () => import('~/pages/dashboard/project/EAReport/ProjectEAReportPreview')
);
