import { CssBaseline } from '@mui/material';
import { Toaster } from 'react-hot-toast';
import { useRoutes } from 'react-router';
import './App.css';
import routes from './routes';

const App: React.FC = () => {
  const content = useRoutes(routes);

  return (
    <>
      <CssBaseline />
      <Toaster />
      {content}
    </>
  );
};

export default App;
